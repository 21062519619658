const Logo = () => {
  return (
    <img
      src="/images/SPK-LOGO.png"
      alt="SPK Logo"
      style={{
        width: "auto",
        height: "72px",
      }}
    />
  );
};

export default Logo;
