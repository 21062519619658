import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useCallback } from "react";
import UserGreeting from "./UserGreeting";
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Chip,
  ClickAwayListener,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import MainCard from "ui-component/cards/MainCard";
import Transitions from "ui-component/extended/Transitions";
//import User1 from "assets/images/users/user1.png";
import { IconLogout, IconSettings } from "@tabler/icons";
import { Form, Input, Modal, Button, Row } from "antd";

import api from '../../../../constant/AxiosInstance';
import { jwtDecode } from "jwt-decode";
import { notification } from "antd";

const ProfileSection = () => {

  const [form] = Form.useForm();

  /* /////////////////////////////////////////////////////////// */

  const theme = useTheme();
  const customization = useSelector((state) => state.customization);

  //const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);

  const anchorRef = useRef(null);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  /* ///////////////////////////////////////////////////////////////// */
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  /* ///////////////////////////////////////////////////////////////// */
  const navigate = useNavigate();

  const [isNewPage, setIsNewPage] = useState(true); // สร้าง state เพื่อเก็บสถานะว่าเป็นการเปิดหน้าใหม่หรือไม่

  const logoutUser = useCallback(() => {
    sessionStorage.removeItem("userRole");
    sessionStorage.removeItem("authToken");
    sessionStorage.removeItem("userId");
    sessionStorage.removeItem("userFirstname");
    sessionStorage.removeItem("userLastname");

    if (!isNewPage) {
      // ตรวจสอบว่าไม่เป็นการเปิดหน้าใหม่ก่อนที่จะแสดงข้อความ
      notification.success({
        message: "ออกจากระบบสำเร็จ",
        description: "คุณได้ออกจากระบบเรียบร้อยแล้ว",
      });
    }

    navigate("/login");
  }, [navigate, isNewPage]);

  const isTokenExpired = (token) => {
    try {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decoded.exp < currentTime;
    } catch (error) {
      return true;
    }
  };

  useEffect(() => {
    const token = sessionStorage.getItem("authToken");
    if (!token || isTokenExpired(token)) {
      logoutUser();
    } else {
      setIsNewPage(false); // เซตเป็น false เมื่อเข้าสู่หน้าแรก
    }
  }, [logoutUser]);

  const handleLogout = () => {
    logoutUser(); // ไม่ต้องส่งพารามิเตอร์เพราะไม่ได้เกิดจากการคลิกปกติ
  };

  // Frontend Code
  const handlePasswordChange = async (values) => {
    try {
      const token = sessionStorage.getItem("authToken");
      if (!token) {
        console.error("Authentication token not found.");
        notification.error({
          message: "ข้อผิดพลาดในการรับรองความถูกต้อง",
          description:
            "ไม่พบโทเค็นการรับรองความถูกต้อง โปรดเข้าสู่ระบบอีกครั้ง",
        });
        return;
      }

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const body = {
        oldPassword: values.passwordPre,
        newPassword: values.newPassword,
      };

      await api.post("/change-password", body, config);
      notification.success({
        message: "เปลี่ยนรหัสผ่านเรียบร้อยแล้ว",
        description: "รหัสผ่านของคุณถูกเปลี่ยนเรียบร้อยแล้ว",
      });
      setIsModalOpen(false);
      form.resetFields();
    } catch (error) {
      console.error("เกิดข้อผิดพลาดในการเปลี่ยนรหัสผ่าน:", error);
      notification.error({
        message: "เกิดข้อผิดพลาดในการเปลี่ยนรหัสผ่าน",
        description:
          error.response?.data?.message ||
          "An unexpected error occurred while trying to change the password.",
      });
    }
  };

  return (
    <>
      <div style={{ paddingRight: "10px" }}>
        <UserGreeting />
      </div>

      <Chip
        sx={{
          height: "48px",
          alignItems: "center",
          borderRadius: "27px",
          transition: "all .2s ease-in-out",
          borderColor: theme.palette.primary.light,
          backgroundColor: theme.palette.primary.light,
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: theme.palette.primary.main,
            background: `${theme.palette.primary.main}!important`,
            color: theme.palette.primary.light,
            "& svg": {
              stroke: theme.palette.primary.light,
            },
          },
          "& .MuiChip-label": {
            lineHeight: 0,
          },
        }}
      

        icon={
          <Avatar
            src="/images/user.png"
            sx={{
              ...theme.typography.mediumAvatar,
              margin: "8px 0 8px 8px !important",
              cursor: "pointer",
            }}
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            color="inherit"
          />
        }
        label={
          <IconSettings
            stroke={1.5}
            size="1.5rem"
            color={theme.palette.primary.main}
          />
        }
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                >
                  <Box sx={{ p: 2 }}>
                    <List
                      component="nav"
                      sx={{
                        width: "100%",
                        maxWidth: 300,
                        minWidth: 200,
                        backgroundColor: theme.palette.background.paper,
                        borderRadius: "10px",
                        [theme.breakpoints.down("md")]: {
                          minWidth: "100%",
                        },
                        "& .MuiListItemButton-root": {
                          mt: 0.5,
                        },
                      }}
                    >
                      <ListItemButton
                        sx={{
                          borderRadius: `${customization.borderRadius}px`,
                        }}
                        onClick={showModal}
                        //selected={selectedIndex === 0}
                        //onClick={(event) => handleListItemClick(event, 0, "#")}
                      >
                        <ListItemIcon>
                          <IconSettings stroke={1.5} size="1.3rem" />
                        </ListItemIcon>

                        <ListItemText
                          primary={<Typography>เปลี่ยนรหัสผ่าน</Typography>}
                        />
                      </ListItemButton>

                      <ListItemButton
                        sx={{
                          borderRadius: `${customization.borderRadius}px`,
                        }}
                        //selected={selectedIndex === 4}
                        onClick={handleLogout}
                      >
                        <ListItemIcon>
                          <IconLogout stroke={1.5} size="1.3rem" />
                        </ListItemIcon>
                        <ListItemText
                          primary={<Typography>ออกจากระบบ</Typography>}
                        />
                      </ListItemButton>
                    </List>
                  </Box>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
      {/* ////////////////////////////////////////////////////////////// */}

      <Modal
        title="เปลี่ยนรหัสผ่าน"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className="text-descriptions"
        footer={null}
        zIndex={1400}
      >
        <Form
          layout="vertical"
          className="text-descriptions"
          form={form}
          size="large"
          onFinish={handlePasswordChange}
          autoComplete="off"
        >
          <Form.Item
            label="รหัสผ่านเดิม"
            name="passwordPre"
            rules={[{ required: true, message: "กรุณาใส่รหัสผ่านเดิม" }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="รหัสผ่านใหม่"
            name="newPassword"
            rules={[
              { required: true, message: "กรุณาใส่รหัสผ่านใหม่" },
              { min: 4, message: "รหัสผ่านต้องความยาวอย่างน้อย 4 ตัวอักษร" },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="ยืนยันรหัสผ่าน"
            name="confirmPassword"
            rules={[
              { required: true, message: "กรุณายืนยันรหัสผ่านใหม่" },
              ({ getFieldValue }) => ({
                validator: (_, value) => {
                  if (!value) {
                    return Promise.resolve(); // ถ้ายังไม่ได้กรอก ก็ไม่ต้องแสดงข้อความ
                  }
                  if (value !== getFieldValue("newPassword")) {
                    return Promise.reject(new Error("รหัสไม่ตรงกัน"));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>

          {/*   <Form.Item
            label="ยืนยันรหัสผ่าน"
            name="confirmPassword"
            rules={[
              { required: true, message: "กรุณายืนยันรหัสผ่านใหม่" },
              ({ getFieldValue }) => ({
                validator: (_, value) => {
                  if (
                    !getFieldValue("newPassword") ||
                    value !== getFieldValue("newPassword")
                  ) {
                    return Promise.reject(new Error("รหัสไม่ตรงกัน"));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item> */}
          <Row gutter={[16, 8]} justify="end">
            <Button
              type="primary"
              htmlType="submit"
              style={{
                width: "100%",
              }}
            >
              เปลี่ยนรหัสผ่าน
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ProfileSection;
